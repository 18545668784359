import React, { useState } from 'react';
import './App.css';

import base from './patterns/base.js';
import asylum from './patterns/asylum.js';

function App() {

  const patterns = [asylum];

  const [pattern, setPattern] = useState(0);
  const [selected, setSelected] = useState([]);
  const [additional, setAdditional] = useState([]);
  const [toilet, setToilet] = useState('default');

  if (!pattern) setPattern(patterns[0]);

  if (!pattern.y) return;

  const cells = [];
  pattern.y.map(letter => {
    pattern.x.map( number => {
      cells.push(`${letter}${number}`);
    });
  });

  const players = 3;
  const squaresToSelect = players * 3;

  const refresh = () => {
    // select selected squares
    const selectedSquares = [];
    new Promise((resolve, reject) => {
      while(selectedSquares.length < squaresToSelect) {
        const rand = cells[Math.floor(Math.random() * cells.length)];
        if (!selectedSquares.includes(rand) && !pattern.disabled.includes(rand)) {
          selectedSquares.push(rand);
        }
      }
      setSelected(selectedSquares);
      setAdditional([]);
      resolve();
    })
    .then(() => {
      let foundToilet = false;
      // select toilet square
      while(!foundToilet) {
        const rand = pattern.toilets[Math.floor(Math.random() * pattern.toilets.length)];
        if (!selectedSquares.includes(rand) && !pattern.disabled.includes(rand)) {
          foundToilet = true;
          setToilet(rand);
        }
      }
    });
  }

  const addMedocs = () => {
    const baseLength = additional.length;
    const copy = additional.slice(0);
    while(copy.length < baseLength + 2) {
      const rand = cells[Math.floor(Math.random() * cells.length)];
      if (!selected.includes(rand) && !pattern.disabled.includes(rand) && !copy.includes(rand)) {
        copy.push(rand);
      }
    }
    setAdditional(copy);
  }

  return (
    <div className="App">
      <title>Asylum Frienzy</title>
      <div className="egj-container">
        <div className="egj-controls">
          {/* <h2>Sélectionnez votre carte!</h2>
          {patterns.map( pattern => <button className='btn btn--stripe' onClick={() => setPattern(pattern)}>{pattern.label}</button>)} */}
          <h1>Asylum Frienzy</h1>
          <p>Bienvenue sur votre compagnon! Toutes les règles du jeu sont disponibles ici: <br/><a href="https://www.notion.so/Asylum-Frenzy-1448a3d114854d18ba61653074b94026" target="_blank" className="btn btn--stripe mt-1">Les règles d'Asylum frienzy</a></p>
        </div>
        <table className="egj-table">
          <tbody>
          { pattern.y.map(letter =><tr key={letter+'asdf'}>{ pattern.x.map( number => {
            const cell = `${letter}${number}`;
            let classes = [];
            if (selected.includes(cell)) classes.push('active');
            if (pattern.disabled.includes(cell)) classes.push('disabled');
            if (additional.includes(cell)) classes.push('additional');
            if (toilet === cell) classes.push('toilet');
            classes = classes.join(' ');
            return <td className={ classes } key={`${cell}-cell`}></td>
            }) }</tr>) }
          </tbody>
        </table>
        <div className="egj-controls">
          <button className='btn btn--stripe mt-1' onClick={() => refresh()}>🌤 Nouvelle journée!</button>
          {selected.length > 0 &&
            <button className='btn btn--stripe mt-1' onClick={() => addMedocs()}>➕ Ajouter 2 médocs!</button>
          }
        </div>
      </div>
    </div>
  );
}

export default App;
