const y = [ 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];
const x = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const disabled = [
  'c3', 'c4', 'd3', 'd4',
  'c9', 'c10', 'd9', 'd10',
  'f3','f4','g3','g4',
  'f9','f10', 'g9', 'g10'
];

const toilets = ['a1','a2','a3','a4','a5','a6','a7','a8','a9','a10','a11','a12','c1','d1','e1','f1','g1','h1','i1','a12','b12','c12','d12','e12','f12','g12','h12','i12','i2','i3','i4','i5','i6','i7','i8','i9','i10','i11'];

export default {
  label: 'Asylum',
  x: x,
  y: y,
  disabled: disabled,
  toilets: toilets
};
